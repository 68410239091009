import React from "react"
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import ogimage from "../images/ogimages/orchestration.png";
import dydxLogo from "../images/logos/dydx-logo.svg";
import osmosisLogo from "../images/logos/osmosis-logo.svg";
import skipLogo from "../images/logos/skip-logo.svg";
import axelarLogo from "../images/logos/axelar-logo.svg";
import uxLogo from "../images/logos/ux-logo.png";
import unionLogo from "../images/logos/union-logo.png";
import strideLogo from "../images/logos/stride-logo.svg";
import cosmosLogo from "../images/logos/cosmos-logo.svg";

import illustration1 from "../images/illustrations/illustration-1.svg";
import illustration2 from "../images/illustrations/illustration-2.svg";
import illustration3 from "../images/illustrations/illustration-3.svg";
import illustration4 from "../images/illustrations/illustration-4.svg";
import illustration5 from "../images/illustrations/illustration-5.svg";
import illustration6 from "../images/illustrations/illustration-6.svg";
import interTrade from "../images/inter-trade.png";
import istIcon from "../images/ist-icon.png";
import nobleImage from "../images/noble_big_image.jpg";
import nobleLogo from "../images/noble_small_icon.png";

export default function OrchestrationPage({data}) {
    const dataProvider = new DataProvider(data);

    const roadmapElements = [
        {
            title: 'Upgrade 17 - Deploys API on-chain!',
            subtitle: 'Q4 2024',
            checked: true,
        },
        {
            title: 'API Update 1, Aria',
            subtitle: 'Q4 2024',
            checked: false,
        },
        {
            title: 'EVM Expansion Pack',
            subtitle: 'H1 2025',
            checked: false,
        },
        {
            title: 'Second Major Expansion Pack',
            subtitle: 'H2 2025',
            checked: false,
        },
        {
            title: 'API Update 2, Bolero',
            subtitle: 'H2 2025',
            checked: false,
        },
    ]
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Orchestration',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Orchestration',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Orchestration</title>
            </Helmet>
            <div className={'orchestration-page'}>

                <section className={'hero-section'}>
                    <div className={'container'}>
                        <h6>Orchestration</h6>
                        <h1>Multi-chain liquidity <br/>
                            at your fingertips </h1>

                        <p className={'lead'}>
                            Build feature-rich applications that can orchestrate assets and services across the
                            interchain.
                        </p>

                        <div className={'button-container'}>
                            <div className={'row justify-content-center align-items-center'}>
                                <div className={'col-auto'}>
                                    <a href="https://agoric.com/blog/announcements/agoric-orchestration-api-live-on-mainnet-long-lived-smart-contracts-now"
                                       className={'btn'} target={`_blank`}>
                                        Start Building
                                    </a>
                                </div>
                                <div className={'col-auto px-3'}>
                                    <a href="https://docs.agoric.com/guides/orchestration/getting-started/key-concepts.html"
                                       target={`_blank`} className={'link mt-0'}>
                                        Orchestration Docs
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className={'logo-section'}>
                    <div className={'container'}>
                        <p className={'text-center mb-5'} id={`orchestrate-section`}>Orchestrate seamless user
                            experiences with a growing number of connected chains and services.</p>
                        <div className={'d-flex flex-row flex-wrap justify-content-center align-items-center'}>
                            <div className={'col-auto p-3'}>
                                <img src={dydxLogo} alt="DYDX"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={osmosisLogo} alt="Osmosis"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={skipLogo} alt="Skip"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={axelarLogo} alt="Axelar"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={uxLogo} alt="UX"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={unionLogo} alt="Union"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={strideLogo} alt="Stride"/>
                            </div>
                            <div className={'col-auto p-3'}>
                                <img src={cosmosLogo} alt="Cosmos"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={'text-section pt-5'}>
                    <div className={'container pt-5'}>
                        <div className="row project--header section--header mt-3">
                            <div className="col-lg-6"><h2>Orchestration brings it all together</h2></div>
                            <div className="col-lg-5 offset-lg-1 d-flex flex-wrap align-items-center">
                                <div className={'col-12'}>
                                    <p className={''}>
                                        Expand your app’s universe to new ecosystems and with new features by seamlessly
                                        incorporating the best protocols and most liquidity from anywhere in the growing
                                        multi-chain ecosystem.

                                        The best part? It’s already built into Agoric’s system design.
                                    </p>
                                </div>
                                <div className={'col-12'}>
                                    <div className={'button-container mt-4'}>
                                        <div className={'d-flex flex-wrap align-items-center'}>
                                            <div className={'col-auto'}>
                                                <a href="/blog/announcements/introducing-agoric-orchestration"
                                                   className={'btn'}>
                                                    Read Announcement
                                                </a>
                                            </div>
                                            <div className={'col-auto pt-3 pt-md-0'}>
                                                <a
                                                    href="https://www.youtube.com/watch?v=4I89sWGSyys"
                                                    target={'_blank'} className={'link px-3'}
                                                >
                                                    Watch Presentation
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-with-image-section right-to-left">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-left col-lg-6 order-lg-2 order-2">
                                <h6>CONCURRENT</h6>
                                <h3>Async & Multi-Block</h3>
                                <p className={'fs-5 '}>Turn your sandbox into a beach. Agoric supports long-lived
                                    processes, allowing your applications to await and respond to cross-chain
                                    actions.</p>
                                <p><b className={'text-black'}>How is this different from other blockchains?</b><br/>
                                    Cross-chain operations are intrinsically asynchronous and multi-block. Most
                                    blockchains require programs to finish executing in the same block in which they
                                    begin.
                                </p>
                                <p>
                                    This makes it challenging to design the intricate contract logic behind the most
                                    novel on-chain activities.
                                </p>
                            </div>
                            <div className="col-right col-lg-6 order-lg-1 order-1 text-lg-left pe-5">
                                <div className="image mx-auto d-table">
                                    <img src={illustration1} alt="Async & Multi-Block"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-with-image-section left-to-right">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-left col-lg-6 order-lg-1 order-2 pe-5">
                                <h6>AUTONOMOUS</h6>
                                <h3>Timers</h3>
                                <p className={'fs-5 '}>Timers offer a new dimension to your application. Whether
                                    building a subscription or mortgage, on-chain timers can listen to contract calls
                                    and take action at a future time. </p>
                                <p>
                                    <b className={'strong'}>Why don’t other chains use timers?</b><br/>
                                    On Agoric, timers are easy because they fit smoothly into Agoric's async
                                    architecture. Agoric smart contracts can autonomously awaken and perform new actions
                                    at predefined times, paving the way for more intricate applications.
                                </p>
                            </div>
                            <div className="col-right col-lg-6 order-lg-2 order-1 text-lg-left">
                                <div className="image mx-auto d-table">
                                    <img src={illustration2} alt="Timers"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-with-image-section right-to-left pb-5">
                    <div className="container pb-5">
                        <div className="row align-items-center mb-3">
                            <div className="col-left col-lg-6 order-lg-2 order-2">
                                <h6>EXTENSIBLE</h6>
                                <h3>Interoperability</h3>
                                <p className={'fs-5 '}>Teams throughout the crypto industry are working to create
                                    seamless multi-chain applications. Success here will unite fragmented liquidity and
                                    unlock new categories of experiences that combine assets and services regardless of
                                    which chain they're on.</p>
                                <p>
                                    <b className={'strong'}>Where does Agoric fit in the interchain?</b><br/>
                                    Native connections to the Inter-Blockchain Communication Protocol (IBC) and
                                    extensibility to include transport solutions like Axelar's General Message Passing
                                    (GMP) give builders access to liquidity and capabilities across the growing
                                    interchain ecosystem.
                                </p>
                            </div>
                            <div className="col-right col-lg-6 order-lg-1 order-1 text-lg-left pe-5">
                                <div className="image mx-auto d-table">
                                    <img src={illustration3} alt="Interoperability"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-with-image-section right-to-left bg-blue">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-left col-lg-6 order-lg-2 order-2">
                                <h2>The world is going <br/>multi-chain</h2>
                                <p>
                                    Users are increasingly exploring multi-chain solutions to solve their problems.
                                    Orchestration gives developers the platform to program those solutions, and access
                                    to the Total Reachable Value of the entire interchain ecosystem.
                                </p>

                                <div className={'d-flex stat'}>
                                    <div className={'col'}>
                                        <div className={'value'}>
                                            59B+
                                        </div>
                                        IBC Market Cap
                                    </div>
                                    <div className={'col'}>
                                        <div className={'value'}>
                                            230K
                                        </div>
                                        IBC DAU
                                    </div>
                                </div>
                            </div>
                            <div className="col-right col-lg-6 order-lg-1 order-1 text-lg-left pe-5">
                                <div className="image mx-auto d-table">
                                    <img src={illustration4} alt="The world is going multi-chain"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={'two-col-section'}>
                    <div className={'container'}>
                        <h2 className={'big'}>Orchestrate the next big app</h2>
                        <p className={'text-center lead'}>Build amazing applications with access to the best features
                            across the interchain, from any blockchain. See what kind of unique, multi-chain experiences
                            you can orchestrate with Agoric.</p>

                        <div className={'button-container mt-4'}>
                            <div className={'row justify-content-center align-items-center'}>
                                <div className={'col-auto'}>
                                    <a href="https://docs.agoric.com/guides/orchestration/getting-started/contract-walkthroughs.html"
                                       className={'btn'} target={`_blank`}>
                                        Pointer Examples
                                    </a>
                                </div>
                                <div className={'col-auto px-3'}>
                                    <a href="https://docs.agoric.com/guides/orchestration/	"
                                       target={`_blank`} className={'link mt-0'}>
                                        Full Documentation
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={'row row-cols-lg-2 two-col gx-5'}>
                            <div className={'pe-md-5'}>
                                <div className={'two-col-item pb-5 pb-lg-0'}>
                                    <div className={'image-container flex-column'}>
                                        <img src={illustration5} alt="Stablecoin Yield Aggregator"/>
                                    </div>
                                    <div className={'title'}>
                                        Stablecoin Yield Aggregator
                                    </div>
                                    <p>
                                        With Orchestration, build apps that can maximize cross-chain yield on stablecoin
                                        deposits.
                                    </p>
                                    <ol>
                                        <li>
                                            User deposits stablecoin with Yield Aggregator contract
                                        </li>
                                        <li>
                                            Contract scans the interchain for best lending rates with ICQ
                                        </li>
                                        <li>
                                            Contract periodically assesses rates and rebalances
                                        </li>
                                    </ol>
                                    <p className={'text-small'}>
                                        All with a single signature from the user.
                                    </p>
                                </div>
                            </div>
                            <div className={''}>
                                <div className={'two-col-item pt-5 pt-lg-0'}>
                                    <div className={'image-container'}>
                                        <img src={illustration6} alt="Cross-Chain Liquidator"/>
                                    </div>
                                    <div className={'title'}>
                                        Cross-Chain Liquidator
                                    </div>
                                    <p>
                                        With Orchestration, build apps that lock and liquidate cross-chain assets for
                                        collateral.
                                    </p>
                                    <ol>
                                        <li>
                                            Contract identifies positions eligible for liquidation
                                        </li>
                                        <li>
                                            Collateral sold through the AMM with the best price
                                        </li>
                                        <li>
                                            Collateral is sent to liquidator’s recipient address
                                        </li>
                                    </ol>
                                    <p className={'text-small'}>
                                        All with a single signature from the user.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className={'two-col-section bg-grey'}>
                    <div className={'container'}>
                        <h2>What’s your multi-chain plan?</h2>
                        <p className={'text-center lead'}>Developers are future-proofing their applications with
                            Orchestration</p>


                        <div className={'row row-cols-lg-2 two-col gx-5 mt-5'}>
                            <div className={''}>
                                <div className={'two-col-item pb-5 pb-lg-0'}>
                                    <div className={'image-container with-bg'}
                                         style={{backgroundImage: `url(${interTrade})`}}>

                                    </div>
                                    <div className={'title title-small'}>
                                        Agoric Orchestration accelerates IST transactions across chains, optimizing liquidity management with enhanced efficiency.
                                    </div>

                                    <div className={'author'}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col-auto'}>
                                                <img src={istIcon} alt="Noble" width={40} height={40}/>
                                            </div>
                                            <div className={'col-auto ps-1'}>
                                                <div className={'name'}>
                                                    Ric Shreves
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-auto title'}>
                                                        President
                                                    </div>
                                                    <div className={'col-auto px-0'}>•</div>
                                                    <div className={'col-auto'}>DCF</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={''}>
                                <div className={'two-col-item pb-5 pb-lg-0'}>
                                    <div className={'image-container with-bg'}
                                         style={{backgroundImage: `url(${nobleImage})`}}></div>
                                    <div className={'title title-small'}>
                                        Fast USDC is an exciting use case that leverages Noble’s unique architecture and
                                        we are thrilled to partner with the Agoric team on this important initiative.
                                    </div>

                                    <div className={'author'}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col-auto'}>

                                                <img src={nobleLogo} alt="Noble" width={40} height={40}/>
                                            </div>
                                            <div className={'col-auto ps-1'}>
                                                <div className={'name'}>
                                                    Jelena Djuric
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-auto title'}>
                                                        CEO
                                                    </div>
                                                    <div className={'col-auto px-0'}>•</div>
                                                    <div className={'col-auto'}>Noble</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-with-image-section left-to-right list-section position-relative">
                    <div id={'take-your-seat'} className={'position-absolute'} style={{marginTop: '-300px'}}></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-left col-lg-6 order-lg-1 order-1 pe-5">
                                <h2>Apply For The Early Access Program</h2>
                                <p>
                                    Enjoy personalized, white-glove technical and marketing support designed to help you
                                    navigate and succeed on our platform.
                                </p>
                                <div className={'row align-items-center mt-4'}>
                                    <div className={'col-auto'}>
                                        <a href="/developers/earlyaccessprograminvite/" className={'btn'}>
                                            Apply Today
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-right col-lg-5 offset-lg-1 order-lg-2 order-2 text-lg-left pt-5 pt-lg-0">
                                <p className={'lead'}>How we support your developer journey:</p>
                                <ul>
                                    <li>
                                        White-Glove Support
                                    </li>
                                    <li>
                                        Financial Incentives
                                    </li>
                                    <li>
                                        Composer Status
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='roadmap bg-grey mb-5 mb-md-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-5 col-lg-4'>
                                <h2 id='roadmap'>
                                    Orchestration API Roadmap
                                </h2>
                                {/*<div className='text'>*/}
                                {/*    <Link to={content.ctaUrl} className={`btn`}>*/}
                                {/*        {content.ctaText}*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                            </div>
                            <div className='col-12 col-md-6 ms-auto pt-5 pt-md-0'>
                                <ul>
                                    {roadmapElements.map((element, index) => {
                                        return (
                                            <li id='roadmap-item' className={element.checked && "active"}
                                                key={index}>
                                                <div className='inner'>
                                                    <div className='date'>{element.subtitle}</div>
                                                    <div className='title'>{element.title}</div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}
